<template>
  <div>
    <sqr-input-date />
  </div>
</template>

<script>
import SqrInputDate from '@/sqrd-ui/SqrInputDate';
export default {
  name: 'DateEdit',
  components: { SqrInputDate }
};
</script>
