<template>
  <div>
    <div class="flex justify-between mb-1" v-if="label">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        label
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="required">
        {{ $t ? $t('sqrd_ui_required') : 'Required' }}
      </span>
    </div>
    <input
      v-bind="$attrs"
      v-on="sqrListeners"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
      class="form-input"
      :class="inputClass"
      type="date"
      :value="value"
      :placeholder="placeholder || label"
    />
    <slot name="invalid" v-if="v && v.$error">
      <p
        class="mt-1 text-xs text-red-600"
        v-if="v.$params.required && !v.required"
      >
        <span v-if="$t">{{
          $t('sqrd_ui_validation_required', { label })
        }}</span>
        <span v-else> {{ label }} is required.</span>
      </p>
      <p
        class="mt-1 text-xs text-red-600"
        v-if="v.$params.maxLength && !v.maxLength"
      >
        {{ label }} is too long (max {{ v.$params.maxLength.max }} chars).
      </p>
    </slot>
  </div>
</template>

<style src="./SqrInput.postcss" lang="postcss" scoped></style>

<script>
import SqrInput from './SqrInput';
export default {
  name: 'SqrdInputText',
  mixins: [SqrInput],
};
</script>
